import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
const GetCTA = (props) => {
    const data = useStaticQuery(graphql`
        query {
        allStrapiAllMenus {
          edges {
            node {
              id
              Label
              Show_In_Burger_Menu
              URL
              main_parent {
                URL
                Show_In_Burger_Menu
                Label
              }
              sub_parent {
                URL
                Label
              }
            }
          }
        }  
        }
    `);
    return (
        <>
        {data.allStrapiAllMenus.edges.map(({node}, key) => {
            return<>
            {node.URL === props.link &&  node.main_parent === null && node.sub_parent === null &&
                <Link to={`/${node.URL}/`}>--- {props.label}</Link>
            }
            {node.URL === props.link &&  node.main_parent != null && node.sub_parent === null &&
                <Link to={`/${node.main_parent.URL}/${node.URL}/`}>--- {props.label}</Link>
            }
            {node.URL === props.link &&  node.main_parent != null && node.sub_parent != null &&
                <Link to={`/${node.main_parent.URL}/${node.sub_parent.URL}/${node.URL}/`}>--- {props.label}</Link>
            }
            </>
        })}
        </>
    )
}
export default GetCTA
